<template>
  <div>
    <b-row class="mb-2" cols="1" cols-sm="2">
      <b-col align-self="center">
        <h4 class="mb-2 mb-md-0">ประวัติค่าคอมมิชชั่นประจำเดือน</h4>
      </b-col>
      <b-col>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="cDate"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="pageLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
    <table-server-side-v-1-widget
      ref="refTableUserAfTransactions"
      url="api/admin/affiliate/dashboard/query-transaction-data"
      :columns="columns"
      :load-first="false"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  props: {
    userId: {
      type: String,
      default: '',
    },
    dateSelectOptions: {
      type: Array,
      default: () => [],
    },
    dateSelected: {
      type: String,
      default: null,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      columns: [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: 'created_at',
          formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm'),
        },
        {
          label: 'ชื่อรูปหลัก',
          field: 'topic_image_name',
        },
        {
          label: 'ชื่อรูปรอง',
          field: 'topic_image_list_data_title',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ราคารูป',
          field: 'topic_image_list_price',
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'currency',
        },
        {
          label: 'อัตตราค่าคอมมิชชั่น (%)',
          field: 'commission_rate',
          formatFn: value => `${Number(value)}%`,
          thClass: 'text-center',
          tdClass: 'text-center text-danger',
        },
        {
          label: 'ค่าคอมมิชชั่นที่ได้รับ',
          field: 'revenue',
          thClass: 'text-center',
          tdClass: 'text-center text-success',
          type: 'currency',
        },
      ],
    }
  },
  computed: {
    cDate: {
      get() {
        return this.dateSelected
      },
      set(val) {
        this.$emit('changeDateSelect', val)
      },
    },
  },
  watch: {
    dateSelected(newVal) {
      this.$refs.refTableUserAfTransactions.updateParams({ af_id: this.userId, date: newVal })
      this.$refs.refTableUserAfTransactions.reloading()
    },
  },
  mounted() {
    if (this.dateSelected && this.userId) {
      this.$refs.refTableUserAfTransactions.updateParams({ af_id: this.userId, date: this.dateSelected })
      this.$refs.refTableUserAfTransactions.reloading()
    }
  },
}
</script>

<style lang="scss" scoped></style>
